const columns = [
    {
        label: "ID",
        key: "id",
        isPrimary: true,
        canEdit: false,
    },
    {
        label: "中文标题",
        key: "cnTitle",
    },
    {
        label: "中文描述",
        key: "cnDescription",
    },
    {
        label: "英文标题",
        key: "engTitle",
        showInList: false,
    },
    {
        label: "英文描述",
        key: "engDescription",
        showInList: false,
    },
    {
        label: "图片",
        key: "thumbnailUrl",
        type: "image",
        canEdit: false,
        showInList: true,
    },
    {
        label: "图片",
        key: "pictureKey",
        type: "image",
        showInList: false,
        canEdit: false,
    },
    {
        label: "排序",
        key: "sort",
        type: "number",
        showInList: false,
        canEdit: true,
    },
    {
        label: "图片",
        key: "ossFile",
        type: "image",
        showInList: false,
    },
    {
        label: "视频",
        key: "ossVideoFile",
        type: "video",
        showInList: false,
    },
];
export default columns;
