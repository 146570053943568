import { createApi } from "../../../shared/libs/table-engine";
/**
 * 菜单相关接口
 */
export const topicApi = createApi({
    baseUrl: "/m/product",
    responseParser: {
        query({ data, ...res }) {
            debugger;
            return { ...res, data: { roleIds: data } };
        },
    },

    requestParams: {
        query(id) {
            return {
                url: `/user/${id}/role`,
            };
        },
        update: ({ avatar, ...data }) => {
            let avatarString = avatar
                ? typeof avatar === "object"
                    ? avatar.url
                    : avatar
                : "";
            data.roleIds = (data?.roleIds || []).map((id) => {
                return id + "";
            });
            return {
                type: "PUT",
                url: "/user",
                data: { ...data, avatar: avatarString },
            };
        },
        create: ({ avatar, ...data }) => {
            let avatarString = avatar
                ? typeof avatar === "object"
                    ? avatar.url
                    : avatar
                : "";

            return {
                url: "register",
                type: "POST",
                data: { ...data, avatar: avatarString },
            };
        },
    },
});
