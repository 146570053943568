import CommonTable from "../../shared/components/CommonTable";
import apiFactory from "../../apis/apiFactory";
import columns from "./configs/table";

const list = "m/gallery/list";
const edit = "m/product/";
const update = "m/product/";
const create = "m/product/create";
const remove = "m/product/";
/**
 * 通用增删改查页面
 */
export default function Gallery() {
    const apiInstance = apiFactory({
        name: "gallery",
        baseUrl: "m/",
        onCallApi(method, params) {
            if (method === "list" && params.url === list) {
                params.url = "m/product/list";
                params.data.type = "1";
            }
            if (method === "query") {
                params.url = `${edit}${params.url.split("/").pop()}`;
            }
            if (method === "remove") {
                params.url = `${remove}${params.url.split("/").pop()}`;
            }
            if (method === "update") {
                params.url = `${update}${params.url.split("/").pop()}`;
                params.data.type = "1";
                params.data.cnTitle =
                    params.data.cnTitle || params.data.engTitle || "默认填充";
                params.data.cnDescription =
                    params.data.cnDescription ||
                    params.data.cnDescription ||
                    "默认填充";
            }
            if (method === "create") {
                params.url = create;
                params.data.type = "1";
                params.data.ossKeyId = Number(params.data.ossFile.id);
                params.data.engTitle = "Default";
                params.data.engDescription = "Default";
                params.data.cnTitle = "默认填充";
                params.data.cnDescription = "默认填充";
                if (params.data?.ossVideoFile?.id) {
                    params.data.ossVideoKeyId = Number(
                        params.data.ossVideoFile.id,
                    );
                }
            }
        },
    });
    return <CommonTable columns={columns} api={apiInstance} />;
}
