import { registerTable } from "../../../shared/libs/table-engine";
import { topicApi } from "./api";
/**
 * 用户表
 */

//  account: "admin"
//  avatar: "http://localhost:8081/static/67f57d2058984103afc54d164aff5648.jpeg"
//  createDate: "2021-11-15T16:09:23.000Z"
//  email: "123@qq.com"
//  id: "1"
//  phoneNum: "18374914568"
//  status: 1
//  type: 1
//  updateDate: "2021-11-15T16:09:23.000Z"

// "id": "string",
//       "name": "string",
//       "remark": "string"

export const topicTable = registerTable("topic", {
    api: topicApi,
    structure: [
        {
            label: "ID",
            key: "id",
            isPrimary: true,
            canEdit: false,
        },
        {
            label: "中文标题",
            key: "cnTitle",
            alias: "title",
        },
        {
            label: "中文描述",
            key: "cnDescription",
            alias: "description",
        },
        {
            label: "英文标题",
            key: "engTitle",
            showInList: false,
        },
        {
            label: "英文描述",
            key: "engDescription",
            showInList: false,
        },
        {
            label: "专题位置",
            key: " type",
            showInList: false,
            type: [
                {
                    label: "左侧",
                    value: 1,
                },
                {
                    label: "右侧",
                    value: 2,
                },
            ],
            canEdit: true,
        },
        {
            label: "图片",
            key: "thumbnailUrl",
            type: "image",
            canEdit: false,
            showInList: true,
        },
        {
            label: "图片",
            key: "pictureKey",
            type: "image",
            showInList: false,
            canEdit: false,
        },
        {
            label: "排序",
            key: "sort",
            type: "number",
            showInList: false,
            canEdit: true,
        },
        {
            label: "图片",
            key: "ossFile",
            type: "image",
            showInList: false,
        },
        {
            label: "视频",
            key: "ossVideoFile",
            type: "video",
            showInList: false,
        },
    ],
    pageStart: 0,
    canAdd: false,
});
