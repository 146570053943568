const columns = [
    {
        label: "ID",
        key: "id",
        isPrimary: true,
        canEdit: false,
    },
    {
        label: "图片",
        key: "thumbnailUrl",
        type: "image",
        canEdit: false,
        showInList: true,
    },
    {
        label: "图片",
        key: "pictureKey",
        type: "image",
        showInList: false,
        canEdit: false,
    },
    {
        label: "排序",
        key: "sort",
        type: "number",
        showInList: false,
        canEdit: true,
    },
    {
        label: "图片",
        key: "ossFile",
        type: "image",
        showInList: false,
    },
];
export default columns;
